import { useDispatch, useSelector } from "react-redux";
import Map from "../map/Map";
import "./Contacts.scss";
import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import GoogleMaps from "../google-map/GoogleMap";
import {
	getServerLanguageHe,
	getServerLanguageRu,
} from "../../store/server-info/actions";
import { setlanguageHe, setlanguageRu } from "../../store/language/actions";
import { useParams } from "react-router-dom";
import SimpleMap from "../GoogleMap/GoogleMap";

export default function Contacts() {
	const contacts = useSelector((state) => state.main.language);

	const id = useSelector((state) => state?.server?.id);
	const current_language = useSelector((state) => state?.server?.language);

	const GET_LOCATIONS = gql`
	{

		siteById(id: "${id}") {
		  phone
    email
    address
		
	}
	}
	`;

	const { data } = useQuery(GET_LOCATIONS);
	const dispatch = useDispatch();
	const params = useParams();
	const language = localStorage.getItem("curent-language");
	useEffect(() => {
		const mainLang = document.querySelector(".languages__btn_main");
		const secondLang = document.querySelector(".languages__btn_second");

		// if (!params.lang) {
		// 	document
		// 		.querySelectorAll("span")
		// 		.forEach((item) => item.setAttribute("dir", "rtl"));
		// 		document
		// 		.querySelectorAll("a")
		// 		.forEach((item) => item.setAttribute("dir", "rtl"));
		// 	dispatch(getServerLanguageHe());
		// 	dispatch(setlanguageHe());
		// 	document.querySelector(".app").classList.add("he");
		// 	mainLang.setAttribute("data-lang", "he");
		// 	mainLang.textContent = "he";
		// 	secondLang.textContent = "ru";
		// 	secondLang.setAttribute("data-lang", "ru");
		// 	return;
		// }
		if (params.lang === "he" && data) {
			document
				.querySelectorAll("span")
				.forEach((item) => item.setAttribute("dir", "rtl"));
			document
				.querySelectorAll("a")
				.forEach((item) => item.setAttribute("dir", "rtl"));
		}
		if (params.lang === "ru" && data) {
			document
				.querySelectorAll("a")
				.forEach((item) => item.setAttribute("dir", "ltr"));
			document
				.querySelectorAll("span")
				.forEach((item) => item.setAttribute("dir", "ltr"));
		}
		// document.querySelectorAll('p').forEach(i=>i.setAttribute('dir', 'ltr'))
	}, []);
	return (
		<div className="contacts wrapper">
			<div className="contacts__container">
				<div className="contacts__info">
					<div className="contacts__desc">
						<span> {contacts.email}</span>
						<a href={`mailto:${data?.siteById?.email}`}>{data?.siteById?.email}</a>
					</div>
					<div className="contacts__desc">
						<span> {contacts.phone}</span>
						<a href={`tel:${data?.siteById?.phone}`}>{data?.siteById?.phone}</a>
					</div>
					<div className="contacts__desc">
						<span> {contacts.office}</span>

						<a href="#">{data?.siteById?.address}</a>
					</div>
					{/* {contacts.map((item, index) => (
						<div className="contacts__desc" key={index}>
							<span> {item.label}</span>
							<a href="#">{item.desc}</a>
						</div>
					))} */}
				</div>
				<div className="contacts__map">
					{" "}
					<SimpleMap />
				</div>
				{/* <GoogleMaps/> */}
			</div>
		</div>
	);
}
