import { gql, useQuery } from "@apollo/client";
import "./Accordion.scss";

import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setlanguageHe, setlanguageRu } from "../../store/language/actions";
import { getServerLanguageHe, getServerLanguageRu } from "../../store/server-info/actions";
import { useParams } from "react-router-dom";

export default function AccordionBox() {
	const accordion = useSelector(
		(state) => state.main.language.problems.accordion
	);

	const id = useSelector((state) => state?.server);
	const current_language = useSelector((state) => state?.server?.language);

	const GET_LOCATIONS = gql`
	{
		siteById(id: "${id?.id}") {
		  mainPage {
			problemSet {
        title${current_language}
        description${current_language}
			}
		}
	}
	}
	`;

	const { data } = useQuery(GET_LOCATIONS);
	const info = data?.siteById?.mainPage?.problemSet;

	// const { data } = useQuery(GET_LOCATIONS);
	// const info = data?.siteById;
	const language = localStorage.getItem("curent-language");
	const dispatch = useDispatch();
	const params = useParams();
	useEffect(() => {
		const mainLang = document.querySelector(".languages__btn_main");
		const secondLang = document.querySelector(".languages__btn_second");

		const language = localStorage.getItem("curent-language");
		if (params.lang === "he" && data) {
			document
				.querySelectorAll(".accordion-button")
				.forEach((item) => item.setAttribute("dir", "rtl"));

			
			return;
		}
		// if (!params.lang ) {
		// 	document
		// 		.querySelectorAll(".accordion-button")
		// 		.forEach((item) => item.setAttribute("dir", "rtl"));

		// 	dispatch(getServerLanguageHe());
		// 	dispatch(setlanguageHe());
		// 	document.querySelector(".app").classList.add("he");
		// 	mainLang.setAttribute("data-lang", "he");
		// 	mainLang.textContent = "he";
		// 	secondLang.textContent = "ru";
		// 	secondLang.setAttribute("data-lang", "ru");
		// 	return;
		// }
		
		if (params.lang === "ru" && data) {
			document
			.querySelectorAll(".accordion-button")
			.forEach((item) => item.setAttribute("dir", "ltr"));
			document
				.querySelectorAll("p")
				.forEach((item) => item.setAttribute("dir", "ltr"));

		
		}
		// document.querySelectorAll('p').forEach(i=>i.setAttribute('dir', 'ltr'))
	}, [data]);
	return (
		<Accordion defaultActiveKey="0">
			{info?.map((item, index) => (
				<div className="accordion__box" key={index}>
					{/* <div className="accordion__number">{index + 1}</div> */}
					<Accordion.Item eventKey={index}>
						<Accordion.Header>{item[`title${current_language}`]}</Accordion.Header>
						<Accordion.Body>{item[`description${current_language}`]}</Accordion.Body>
					</Accordion.Item>
				</div>
			))}
		</Accordion>
	);
}
