import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { chartLimitation } from "../characterLimitation";

// eslint-disable-next-line react/prop-types
export default function HelmetConfig({ title, desc }) {
	return (
		<Helmet>
			‍<title>{title}</title>‍
			<link
				rel="icon"
				type="image/png"
				href={`https://back.avalon.co.il:8002/media/${desc?.favicon}`}
				sizes="16x16"
			/>
			<meta name="description" content={chartLimitation(desc)} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={chartLimitation(desc)} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={chartLimitation(desc)} />
			<meta
				property="og:image"
				content=""
				data-react-helmet="true"
			/>
			<meta
				property="twitter:image"
				content=""
				data-react-helmet="true"
			/>
		</Helmet>
	);
}
