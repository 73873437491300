export const GET_SERVER_LANGUAGE_RU = 'language/GET_SERVER_LANGUAGE_RU';
export const GET_SERVER_LANGUAGE_HE = 'language/GET_SERVER_LANGUAGE_HE';
export const GET_SITE_ID = 'language/GET_SITE_ID';


export function getServerLanguageRu() {
    return {type: GET_SERVER_LANGUAGE_RU}
}
export function getServerLanguageHe() {
    return {type: GET_SERVER_LANGUAGE_HE}
}

export function getSiteId(id) {
    return {type: GET_SITE_ID, data: id}
}
