import axios from "axios";

export function submitForm(adress, phone, username, id) {

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },

    data: {
      "address": adress,
      "phone": phone,
      "username": username,
      "site": id
    },
    url: 'https://back.avalon.co.il:8002/api/v1/emails/create/'
  };
  axios(options)
  return axios(options)

}
