import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams,Link } from "react-router-dom";

import { setlanguageHe, setlanguageRu } from "../../store/language/actions";
import Languages from "../languages/Languages";
import MobileMenu from "../mobile-menu/MobileMenu";
import Social from "../social/Social";
import "./Header.scss";
import { gql, useQuery } from "@apollo/client";
import {
	getServerLanguageHe,
	getServerLanguageRu,
} from "../../store/server-info/actions";
import DisabledMenu from "./DisabledMenu";

export default function Header({ isMain }) {
	const lang = localStorage.getItem("lang"); 
	const params = useParams();
	// useEffect(() => {
	// 	console.log(params);
	// }, []);
	const header = useSelector((state) => state.main.language.header);
	const dispatch = useDispatch();
	const button = useSelector((state) => state.main.language.buttonsTitle);
	const id = useSelector((state) => state?.server);
	const current_language = useSelector((state) => state?.server?.language);
	const navigate = useNavigate();
	// useEffect(() => {
	// 	document.querySelector(".languages__box").classList.toggle("active");

	// 	const mainLang = document.querySelector(".languages__btn_main");
	// 	const secondLang = document.querySelector(".languages__btn_second");

	// 	const language = localStorage.getItem("curent-language");
	// 	if (params.lang === "he" || !language) {

	// 		document
	// 			.querySelectorAll("p")
	// 			.forEach((item) => item.setAttribute("dir", "rtl"));
	// 		dispatch(getServerLanguageHe());
	// 		dispatch(setlanguageHe());
	// 		document.querySelector(".app").classList.add("he");
	// 		mainLang.setAttribute("data-lang", "he");
	// 		mainLang.textContent = "he";
	// 		secondLang.textContent = "ru";
	// 		secondLang.setAttribute("data-lang", "ru");
	// 		return;
	// 	}
	// 	if (params.lang === "ru") {
	// 		document
	// 			.querySelectorAll("p")
	// 			.forEach((item) => item.setAttribute("dir", "ltr"));
	// 		document.querySelector(".app").classList.remove("he");
	// 		dispatch(getServerLanguageRu());
	// 		dispatch(setlanguageRu());

	// 		mainLang.setAttribute("data-lang", "ru");
	// 		mainLang.textContent = "ru";
	// 		secondLang.textContent = "he";
	// 		secondLang.setAttribute("data-lang", "he");
	// 	}
	// }, []);

	const GET_LOCATIONS = gql`
	{
		siteById(id: "${id?.id}") {
			title
			phone
			email
			address
    logo
		header {
			socialLinks{
				name
				url
			}
      menuItems {
        name${current_language}
      url
      }
		}
		}
	}
	`;

	const { data } = useQuery(GET_LOCATIONS);

	const server_header = data?.siteById;

	const handleClick = (e) => {
		document
			.querySelectorAll(".header__link ")
			.forEach((item) => item.classList.remove("active"));
		e.target.classList.add("active");
		document.querySelector(".mobile-menu").classList.remove("active");
		document.querySelector("body").classList.remove("scroll-hidden");
		document.querySelector(".mobile-button").classList.remove("close");
	};
	const mobileMenu = (e) => {
		document.querySelector(".mobile-menu").classList.toggle("active");
		document.querySelector("body").classList.toggle("scroll-hidden");
		e.target.classList.toggle("close");
	};

	const changeLanguage = (e) => {
		document.querySelector(".languages__box").classList.toggle("active");

		const mainLang = document.querySelector(".languages__btn_main");
		const secondLang = document.querySelector(".languages__btn_second");
	

		if (e.target.getAttribute("data-lang") === "he") {
			localStorage.setItem("lang", "he");
			localStorage.setItem("curent-language", "he");
			document.querySelectorAll("p").forEach((item) => {
				item.setAttribute("dir", "rtl");
			});

			dispatch(getServerLanguageHe());
			document.querySelector(".app").classList.add("he");
			mainLang.setAttribute("data-lang", "he");
			mainLang.textContent = "he";
			secondLang.textContent = "ru";
			secondLang.setAttribute("data-lang", "ru");
			dispatch(setlanguageHe());
			if (params.name && params.lang) {
				// console.log(`/blog/${params.name}/${params.lang}`)
				navigate(`/blog/${params.name}/he`);
				return
			}
			navigate("/he");
		} else {
			localStorage.setItem("curent-language", "ru");

			document.querySelector(".app").classList.remove("he");
			dispatch(getServerLanguageRu());
			document
				.querySelectorAll("p")
				.forEach((item) => item.setAttribute("dir", "ltr"));

			mainLang.setAttribute("data-lang", "ru");
			mainLang.textContent = "ru";
			secondLang.textContent = "he";
			secondLang.setAttribute("data-lang", "he");
			dispatch(setlanguageRu());
			localStorage.setItem("lang", "ru");
			if (params.name && params.lang) {
				// console.log(`/blog/${params.name}/${params.lang}`)
				navigate(`/blog/${params.name}/ru`);
				return
			}
			navigate("/ru");
		}
	};
	const handleDisabledIcon = (e) => {
		document.querySelector(".disabled-menu").classList.toggle("active");
	};
	return (
		<header className="header wrapper">
			{/* <button
					type="button"
					className="header__disabled-icon"
					onClick={handleDisabledIcon}></button> */}
			<DisabledMenu />
			<div className="wrapper-box">
				<div className="header__container">
					<div className="header__language">
						<Social isLight={false} />
						<Languages changeLanguage={changeLanguage} />
					</div>
					<div className="header__box">
						<NavLink
							to={`/${params.lang}`}
							className="header__logo"
							style={{
								background: `center/contain no-repeat url(https://back.avalon.co.il:8002/media/${server_header?.logo})`,
							}}></NavLink>

						<div className="header__row">
							<a className="header__phone" href="tel:0587722964">
								{server_header?.phone}
							</a>
							{/* <Link
								to="form"
								spy={true}
								smooth={true}
								offset={100}
								duration={500}
								className="header__button button-transparent">
								{button.name_course}
							</Link> */}
							<Link
								to={`/${lang}/?scrollTo=form`}
							
								className="header__button button-dark">
								{button.name_appointment}
							</Link>
						</div>
					</div>
					<button onClick={mobileMenu} className="mobile-button"></button>
				</div>
				<nav className="header__nav">
					{(isMain &&
						server_header?.header?.menuItems?.map((link, index) => (
							<Link
								onClick={handleClick}
								key={index}
								to={`/${lang}/?scrollTo=${link.url}`}
								className={link.class ? "header__link active " : "header__link "}>
								{link[`name${current_language}`]}
							</Link>
						))) ||
						server_header?.header?.menuItems?.map((link, index) => (
							<Link
								onClick={handleClick}
								key={index}
								to={`/${lang}/?scrollTo=${link.url}`}
								className={link.class ? "header__link active " : "header__link "}>
								{link[`name${current_language}`]}
							</Link>
						))}
				</nav>
			</div>
			<MobileMenu isMain={isMain} />
		</header>
	);
}
