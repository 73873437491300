import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Contacts from "../../contacts/Contacts";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import "./BlogPage.scss";
import { gql, useQuery } from "@apollo/client";
import DOMParserReact from "dom-parser-react";
import HelmetConfig from "../../helmet/HelmetConfig";
import { getServerLanguageHe, getServerLanguageRu } from "../../../store/server-info/actions";
import { setlanguageHe, setlanguageRu } from "../../../store/language/actions";

export default function BlogPage() {
	const params = useParams();

	useEffect(() => {
		window.scrollTo({ top: 10, left: 0, behavior: "smooth" });
	}, []);
	const blog = useSelector((state) => state.main.language.BlogPage);
	const id = useSelector((state) => state?.server?.id);
	const current_language = useSelector((state) => state?.server?.language);

	const GET_LOCATIONS = gql`
	{

		postBySlug(slug: "${params.name}") {
			text${current_language}
			mainImage
			publish
			title${current_language}
		}
	}
	`;
const dispatch = useDispatch()
	const { data } = useQuery(GET_LOCATIONS);
	const lang = localStorage.getItem("lang");
	const navigate = useNavigate()
	useEffect(() => {
		// console.log(params);
		// document.querySelector(".languages__box").classList.toggle("active");
		// const language = localStorage.getItem("curent-language");

		const mainLang = document.querySelector(".languages__btn_main");
		const secondLang = document.querySelector(".languages__btn_second");
		// console.log(lang);
		if (!params.lang && !lang) {
			navigate(`/he`);
		
		}
		// if (!params.lang && lang) {
			// navigate(`/${lang}`);
	
		// }
		if (params.lang === "he") {
			document.querySelectorAll("p").forEach((item) => {
				item.setAttribute("dir", "rtl");
				localStorage.setItem("lang", "he");
			});

			dispatch(getServerLanguageHe());
			dispatch(setlanguageHe());
			document.querySelector(".app").classList.add("he");
			mainLang.setAttribute("data-lang", "he");
			mainLang.textContent = "he";
			secondLang.textContent = "ru";
			secondLang.setAttribute("data-lang", "ru");
			return;
		}
		if (params.lang === "ru"||lang === 'ru') {
			localStorage.setItem("lang", "ru");
			document
				.querySelectorAll("p")
				.forEach((item) => item.setAttribute("dir", "ltr"));

			document.querySelector(".app").classList.remove("he");
			dispatch(getServerLanguageRu());
			dispatch(setlanguageRu());

			mainLang.setAttribute("data-lang", "ru");
			mainLang.textContent = "ru";
			secondLang.textContent = "he";
			secondLang.setAttribute("data-lang", "he");
		}
	}, []);

	return (
		<>
			<Header />
			<HelmetConfig
				title={data?.postBySlug[`title${current_language}`]}
				desc={data?.postBySlug?.[`text${current_language}`]}
			/>
			<div className="blog-page he">
				<div
					className="blog-page__bg"
					style={{
						backgroundImage: `url(https://back.avalon.co.il:8002/media/${data?.postBySlug?.mainImage})`,
					}}></div>
				<div className="wrapper">
										<div className="wrapper-box">
						<div className="blog-page__container ">
							<p className="blog-page__info">
								<span>{data?.postBySlug?.publish}</span>
								{data?.postBySlug[`title${current_language}`]}
							</p>
							<div className="blog-page__desc">
								<p>
									<DOMParserReact
										source={data?.postBySlug?.[`text${current_language}`]}
									/>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Contacts />
			<Footer />
		</>
	);
}
