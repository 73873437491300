import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { gql, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function SimpleMap() {
	const [getInfo, setGetInfo] = useState();
	const id = useSelector((state) => state?.server?.id);
	const GET_LOCATIONS = gql`
		{
			mapMarkers {
				lat
				lng
				markerText
			}
		}
	`;

	const { data } = useQuery(GET_LOCATIONS);
	const info = data?.mapMarkers;

	useEffect(() => {
		if (info) {
			const [obj] = info;
			setGetInfo(obj);
			// console.log(getInfo)
		}
	}, [data]);

	const defaultProps = {
		center: {
			lat: getInfo?.lat,
			lng: getInfo?.lng,
		},
		zoom: 11,
	};

	return (
		// Important! Always set the container height explicitly
		<div style={{ height: "100%", width: "100%" }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: getInfo?.apiKey }}
				defaultCenter={defaultProps.center}
				defaultZoom={defaultProps.zoom}>
				<AnyReactComponent lat={getInfo?.lat} lng={getInfo?.lng} text={getInfo?.markerText} />
			</GoogleMapReact>
		</div>
	);
}
