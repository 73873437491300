import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import store from './store/store'
import {BrowserRouter} from 'react-router-dom';
// import { ApolloServer } from '@apollo/server';

import {ApolloClient, InMemoryCache, ApolloProvider, gql} from '@apollo/client';
// ApolloServer
const client = new ApolloClient({
  uri: 'https://back.avalon.co.il:8002/graphql',
  fetchOptions: {
    mode: 'same-origin', // no-cors, *cors, same-origin
  },
  cache: new InMemoryCache()
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render (
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
