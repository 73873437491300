import cloneDeep from "lodash.clonedeep"
import {
    GET_SERVER_LANGUAGE_RU,
    GET_SERVER_LANGUAGE_HE,
    GET_SITE_ID
} from "./actions"

const defaultState = {
    language: localStorage.getItem('curent-language') || 'Ru',
    id: null
}

export const ServerReduser = (state = defaultState, action) => {
    switch (action.type) {
 
        case GET_SERVER_LANGUAGE_RU: {
            const clone = cloneDeep(state);
            clone.language = 'Ru';
            return clone;
        };
    case GET_SERVER_LANGUAGE_HE: {
        const clone = cloneDeep(state);
        clone.language = 'He';
        return clone;
    };
    case GET_SITE_ID: {
        const clone = cloneDeep(state);
        clone.id = action.data;
        return clone;
    }
    default:
        return state;

    }
}