import { useDispatch, useSelector } from "react-redux";
import "./Form.scss";
import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { submitForm } from "../../API/email";
import { getMessage } from "../../store/email/actions";
import { getServerLanguageHe, getServerLanguageRu } from "../../store/server-info/actions";
import { setlanguageHe, setlanguageRu } from "../../store/language/actions";

export default function Form() {
	const dispatch = useDispatch();
	const button = useSelector((state) => state.main.language.buttonsTitle);
	const form = useSelector((state) => state.main.language.form);
	const price = useSelector((state) => state.main.language.price);
	const id = useSelector((state) => state?.server?.id);
	const current_language = useSelector((state) => state?.server?.language);
	const message = useSelector((state) => state.main.language.messages);
	const GET_LOCATIONS = gql`
	{
		siteById(id: "${id}") {
		  mainPage {
				coursesBlockTitle${current_language}
				
		}
	}
	}
	`;

	const { data } = useQuery(GET_LOCATIONS);
	const info = data?.siteById;

	const createEmail = (e) => {
		e.preventDefault();
		let name = document.querySelector("#name");
		let phone = document.querySelector("#phone");
		let mail = document.querySelector("#mail");

		submitForm(mail.value, phone.value, name.value, id)
			.then((data) => {
				if (data.status === 201) {
					name.value = "";
					mail.value = "";
					phone.value = "";
					dispatch(getMessage(message.succes));
				}
			})
			.catch((e) => {
				document.querySelector(".message").classList.add("active");
				let timerId = setTimeout(
					() => document.querySelector(".message").classList.remove("active"),
					3000
				);
				if (e.response.data.address) {
					dispatch(getMessage(message.adress));
				} else if (e.response.data.phone) {
					dispatch(getMessage(message.phone));
				} else if (e.response.data.username) {
					dispatch(getMessage(message.username));
					return;
				}
			});
	};

	const language = localStorage.getItem("curent-language");
	// const dispatch = useDispatch();
	// useEffect(() => {
	// 	const mainLang = document.querySelector(".languages__btn_main");
	// 	const secondLang = document.querySelector(".languages__btn_second");

	// 	const language = localStorage.getItem("curent-language");

	// 	if (!language) {
	// 		document
	// 			.querySelectorAll(".form__title")
	// 			.forEach((item) => item.setAttribute("dir", "rtl"));

	// 		dispatch(getServerLanguageHe());
	// 		dispatch(setlanguageHe());
	// 		document.querySelector(".app").classList.add("he");
	// 		mainLang.setAttribute("data-lang", "he");
	// 		mainLang.textContent = "he";
	// 		secondLang.textContent = "ru";
	// 		secondLang.setAttribute("data-lang", "ru");
	// 		return;
	// 	}
	// 	if (language === "he" && data) {
	// 		document
	// 			.querySelectorAll(".form__title")
	// 			.forEach((item) => item.setAttribute("dir", "rtl"));

	// 		dispatch(getServerLanguageHe());
	// 		dispatch(setlanguageHe());
	// 		document.querySelector(".app").classList.add("he");
	// 		mainLang.setAttribute("data-lang", "he");
	// 		mainLang.textContent = "he";
	// 		secondLang.textContent = "ru";
	// 		secondLang.setAttribute("data-lang", "ru");
	// 		return;
	// 	}
	// 	if (language === "ru" && data) {
	// 		document
	// 		.querySelectorAll(".form__title")
	// 		.forEach((item) => item.setAttribute("dir", "ltr"));
	// 		document
	// 			.querySelectorAll("p")
	// 			.forEach((item) => item.setAttribute("dir", "ltr"));

	// 		document.querySelector(".app").classList.remove("he");
	// 		dispatch(getServerLanguageRu());
	// 		dispatch(setlanguageRu());

	// 		mainLang.setAttribute("data-lang", "ru");
	// 		mainLang.textContent = "ru";
	// 		secondLang.textContent = "he";
	// 		secondLang.setAttribute("data-lang", "he");
	// 	}
	// 	// document.querySelectorAll('p').forEach(i=>i.setAttribute('dir', 'ltr'))
	// }, [data, language]);
	return (
		<form className="form">
			<div className="form__box ">
				<div className="wrapper">
					<div className="wrapper-box">
						<div className="form__container">
							<div className="form__info">
								<h2 className="form__title">{form.title}</h2>
								<p className="form__desc">{form.desc}</p>
							</div>
							<div className="form__column">
								<input
									className="form__input "
									id="name"
									type="text"
									placeholder={form.placeholderName}
								/>
								<input
									className="form__input"
									id="mail"
									type="text"
									placeholder={form.placeholderMail}
								/>
								<input
									className="form__input"
									id="phone"
									type="text"
									placeholder={form.placeholderPhone}
								/>
								<button
									type="submit"
									className="button-dark form__button"
									onClick={createEmail}>
									{button.name_appointment}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="about about-courses">
				<p className="about__additional">
					{info?.mainPage[`coursesBlockTitle${current_language}`]}
				</p>
			</div>
		</form>
	);
}
