import cloneDeep from "lodash.clonedeep"
import {SET_LANGUAGE_HE, SET_LANGUAGE_RU} from "./actions"
import {ru} from "./defaultInfo/ru";
import {he} from "./defaultInfo/he";


const defaultState = {
    language: ru
}

export const languageReduser = (state = defaultState, action) => {
    switch (action.type) {
        case SET_LANGUAGE_RU:
            {
                const clone = cloneDeep(state);
                clone.language = ru;
                return clone;
            }
        case SET_LANGUAGE_HE:
            {
                const clone = cloneDeep(state);
                clone.language = he;
                return clone;
            }
        default:
            return state;

    }
}
