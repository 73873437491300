import { useDispatch, useSelector } from "react-redux";
import './DisabledMenu.scss';
import {
	getBackground,
	getFonts,
	getImg,
} from "../../store/disabled-confing/action";
import { useEffect } from "react";

export default function DisabledMenu() {
	const dispatch = useDispatch();
	const state = useSelector((state) => state.disabled.disabled_config);

// const lang = useSelector(state=>state.language.language)

	useEffect(() => {
		localStorage.setItem("disabled-config", JSON.stringify(state));
		// console.log(lang.language)
	}, [state]);

	useEffect(() => {
		let config = JSON.parse(localStorage.getItem("disabled-config"));
		// console.log(config);
		if (config.bg !== null) {
			document.querySelector("html").setAttribute("data-color", config.bg);
		}
		if (config.image !== null) {
			document.querySelector("html").setAttribute("data-img", config.image);
		}
		if (config.fonts !== null) {
			document.querySelector("html").setAttribute("data-increase", config.fonts);
		}
	}, []);
	// 	const disabled_config ={
	// image:null,
	// font:
	// 	}
	const changeFonts = (e) => {
		const { target } = e;
		if (target.classList.contains("increase")) {
			document.querySelector("html").setAttribute("data-increase", "true");
			dispatch(getFonts(true));
			return;
		}
		if (target.classList.contains("decrease")) {
			document.querySelector("html").setAttribute("data-increase", "false");
			dispatch(getFonts(false));

			return;
		}
	};

	const changeColor = (e) => {
		const { target } = e;
		if (target.classList.contains("disabled-menu__color_white")) {
			document.querySelector("html").setAttribute("data-color", "");
			dispatch(getBackground(""));
			return;
		}
		if (target.classList.contains("disabled-menu__color_black")) {
			document.querySelector("html").setAttribute("data-color", "black");
			dispatch(getBackground("black"));

			return;
		}
		if (target.classList.contains("disabled-menu__color_yellow")) {
			document.querySelector("html").setAttribute("data-color", "yellow");
			dispatch(getBackground("yellow"));

			return;
		}
	};

	const changeImage = (e) => {
		const { target } = e;
		if (target.classList.contains("disabled-menu__image_show")) {
			document.querySelector("html").setAttribute("data-img", "show");
			dispatch(getImg("show"));

			return;
		}
		if (target.classList.contains("disabled-menu__image_hidden")) {
			document.querySelector("html").setAttribute("data-img", "hidden");
			dispatch(getImg("hidden"));

			return;
		}
		if (target.classList.contains("disabled-menu__image_inverse")) {
			document.querySelector("html").setAttribute("data-img", "inverse");
			dispatch(getImg("inverse"));
			return;
		}
		if (target.classList.contains("disabled-menu__image_reset")) {
			document.querySelector("html").setAttribute("data-img", "");
			document.querySelector("html").setAttribute("data-color", "");
			document.querySelector("html").setAttribute("data-increase", "false");
			dispatch(getImg(""));
			dispatch(getBackground(""));
			dispatch(getFonts(false));
			return;
		}
	};
	return (
		<div className="disabled-menu">
			<div className="disabled-menu__container">
				<div className="disabled-menu__box">
					<p className="disabled-menu__name">размер шрифта Px</p>
					<div className="disabled-menu__fonts" onClick={changeFonts}>
						<button
							type="button"
							className="disabled-menu__button  disabled-menu__button_decrease decrease"></button>
						<button
							type="button"
							className="disabled-menu__button  disabled-menu__button_increase increase"></button>
					</div>
				</div>
				<div className="disabled-menu__box">
					<p className="disabled-menu__name">Цвет сайта</p>
					<div className="disabled-menu__fonts" onClick={changeColor}>
						<button
							type="button"
							className="disabled-menu__color  disabled-menu__color_white"></button>
						<button
							type="button"
							className="disabled-menu__color  disabled-menu__color_black"></button>
						{/* <button
							type="button"
							className="disabled-menu__color  disabled-menu__color_blue"></button> */}
						<button
							type="button"
							className="disabled-menu__color  disabled-menu__color_yellow"></button>
						{/* <button
							type="button"
							className="disabled-menu__color  disabled-menu__color_pink"></button> */}
					</div>
				</div>
				<div className="disabled-menu__box">
					<p className="disabled-menu__name">ИЗОБРАЖЕНИЯ</p>
					<div className="disabled-menu__fonts" onClick={changeImage}>
						<button
							type="button"
							className="disabled-menu__image  disabled-menu__image_show"></button>
						<button
							type="button"
							className="disabled-menu__image  disabled-menu__image_hidden"></button>
						<button
							type="button"
							className="disabled-menu__image  disabled-menu__image_inverse"></button>
					</div>
				</div>
				<div className="disabled-menu__box">
					<p className="disabled-menu__name">сбросить</p>
					<div className="disabled-menu__fonts" >
						<button
							type="button"
							className="disabled-menu__image  disabled-menu__image_reset"  onClick={changeImage}></button>
					
					</div>
				</div>
			</div>
		</div>
	);
}
