import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { Link } from "react-scroll";
import Social from "../social/Social";
import "./Footer.scss";
import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import SimpleMap from "../GoogleMap/GoogleMap";

export default function Footer({ isMain }) {
	const header = useSelector((state) => state.main.language.header);
	const button = useSelector((state) => state.main.language.buttonsTitle);

	const handleClick = (e) => {
		document
			.querySelectorAll(".header__link ")
			.forEach((item) => item.classList.remove("active"));
		e.target.classList.add("active");
	};

	const id = useSelector((state) => state?.server?.id);
	const current_language = useSelector((state) => state?.server?.language);

	const GET_LOCATIONS = gql`
	{

		siteById(id: "${id}") {
			logo
		  header {
				menuItems {
					name${current_language}
					url
				}
			}
		
	}
	}
	`;
	const params = useParams();
	const { data } = useQuery(GET_LOCATIONS);

	return (
		<footer className="footer">
			<div className="footer__container">
				<div className="wrapper">
					<div className="wrapper-box">
						<nav className=" header header__nav">
							{(isMain &&
								data?.siteById?.header?.menuItems?.map((link, index) => (
									<Link
										onClick={handleClick}
										key={index}
										to={link.url}
										spy={true}
										smooth={true}
										offset={100}
										duration={500}
										className={link.class ? "header__link  " : "header__link  "}>
										{link[`name${current_language}`]}
									</Link>
								))) ||
								data?.siteById?.header?.menuItems?.map((link, index) => (
									<NavLink
										onClick={handleClick}
										key={index}
										to={`/?scrollTo=${link.url}`}
										spy={true}
										smooth={true}
										offset={100}
										duration={500}
										className={link.class ? "header__link  " : "header__link  "}>
										{link[`name${current_language}`]}
									</NavLink>
								))}
						</nav>
						<div className="footer__row">
							<Social isLight={true} />
							<div className="footer__box">
								<NavLink
									to={`/${params.lang}`}
									className="footer__logo"
									style={{
										background: `center/cover no-repeat url(https://back.avalon.co.il:8002/media/${data?.siteById?.logo})`,
									}}></NavLink>
								<div className="footer__flex">
									<Link
										to={"?scrollTo=form"}
										spy={true}
										smooth={true}
										hashSpy={true}
										offset={50}
										duration={500}
										className="footer__button button-transparent">
										{button.name_course}
									</Link>
									<Link
										to="form"
										spy={true}
										smooth={true}
										hashSpy={true}
										offset={50}
										duration={500}
										className="footer__button button-dark">
										{button.name_appointment}
									</Link>
								</div>
							</div>
						</div>
						<p className="footer__copy">2023 © All Rights Reserved</p>
					</div>
				</div>
			</div>
		</footer>
	);
}
