import { useEffect } from "react";
import About from "../../about/About";
import Blog from "../../blog/Blog";
import Contacts from "../../contacts/Contacts";
import Footer from "../../footer/Footer";
import Form from "../../form/Form";
import Header from "../../header/Header";
import Preview from "../../preview/Preview";
import ProblemSolving from "../../problem-solving/ProblemSolving";
import Reviews from "../../reviews/Reviews";
import Services from "../../services/Services";
import "./MainPage.scss";
import {
	getServerLanguageHe,
	getServerLanguageRu,
} from "../../../store/server-info/actions";
import { scroller } from "react-scroll";

import { setlanguageHe, setlanguageRu } from "../../../store/language/actions";
import { useDispatch, useSelector } from "react-redux";
import Hemlet from "../../../Helmet/Helmet";
import { gql, useQuery } from "@apollo/client";
import HelmetConfig from "../../helmet/HelmetConfig";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function MainPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();
	useEffect(() => {}, []);
	const lang = localStorage.getItem("lang");

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const scrollTo = queryParams.get("scrollTo");
		if (scrollTo === "detail") {
			setTimeout(() => {
				scroller.scrollTo("about-courses", {
					duration: 500,
					smooth: true,
					bottom: 0,
				});
				return;
			}, 500);
		}
		if (scrollTo === "form") {
			setTimeout(() => {
				scroller.scrollTo("form", {
					duration: 500,
					smooth: true,
					bottom: 0,
				});
				return;
			}, 500);
		}
		if (scrollTo === "about") {
			setTimeout(() => {
				scroller.scrollTo("about", {
					duration: 500,
					smooth: true,
					bottom: 0,
				});
				return;
			}, 500);
		}
		if (scrollTo === "gallery") {
			setTimeout(() => {
				scroller.scrollTo("gallery", {
					duration: 500,
					smooth: true,
					bottom: 0,
				});
				return;
			}, 500);
		}
		if (scrollTo === "reviews") {
			setTimeout(() => {
				scroller.scrollTo("reviews", {
					duration: 500,
					smooth: true,
					bottom: 0,
				});
				return;
			}, 500);
		}
		if (scrollTo === "blog") {
			setTimeout(() => {
				scroller.scrollTo("blog", {
					duration: 500,
					smooth: true,
					bottom: 0,
				});
				return;
			}, 500);
		}
		if (scrollTo === "services") {
			setTimeout(() => {
				scroller.scrollTo("services", {
					duration: 500,
					smooth: true,
					bottom: 0,
				});
				return;
			}, 500);
		}
		if (scrollTo === "contacts") {
			setTimeout(() => {
				scroller.scrollTo("contacts", {
					duration: 500,
					smooth: true,
					bottom: 0,
				});
				return;
			}, 500);
		}
		if (scrollTo === "problems") {
			setTimeout(() => {
				scroller.scrollTo("problems", {
					duration: 500,
					smooth: true,
					bottom: 0,
				});
				return;
			}, 500);
		}
	}, [location.search, params]);
	useEffect(() => {
		if (lang === "he") {
			return navigate("/he	");
		}
		if (lang === "ru") {
			return navigate("/ru	");
		}
	}, []);
	useEffect(() => {
		// console.log(params);
		// document.querySelector(".languages__box").classList.toggle("active");
		// const language = localStorage.getItem("curent-language");

		const mainLang = document.querySelector(".languages__btn_main");
		const secondLang = document.querySelector(".languages__btn_second");
		// console.log(lang);
		if (!params.lang && !lang) {
			// navigate(`/he`);
		}
		// if (!params.lang && lang) {
		// 	navigate(`/${lang}`);

		// }
		// if (!params.lang && lang) {
		// 	navigate(`/${lang}`);

		// }
		if (params.lang === "he") {
			document.querySelectorAll("p").forEach((item) => {
				item.setAttribute("dir", "rtl");
				localStorage.setItem("lang", "he");
			});

			dispatch(getServerLanguageHe());
			dispatch(setlanguageHe());
			document.querySelector(".app").classList.add("he");
			mainLang.setAttribute("data-lang", "he");
			mainLang.textContent = "he";
			secondLang.textContent = "ru";
			secondLang.setAttribute("data-lang", "ru");
			return;
		}
		if (params.lang === "ru" || lang === "ru") {
			localStorage.setItem("lang", "ru");
			document
				.querySelectorAll("p")
				.forEach((item) => item.setAttribute("dir", "ltr"));

			document.querySelector(".app").classList.remove("he");
			dispatch(getServerLanguageRu());
			dispatch(setlanguageRu());

			mainLang.setAttribute("data-lang", "ru");
			mainLang.textContent = "ru";
			secondLang.textContent = "he";
			secondLang.setAttribute("data-lang", "he");
		}
	}, []);

	// useEffect(() => {
	// 	document.querySelector(".languages__box").classList.toggle("active");
	// 	const language = localStorage.getItem("curent-language");

	// 	const mainLang = document.querySelector(".languages__btn_main");
	// 	const secondLang = document.querySelector(".languages__btn_second");

	// 	if (language === "he" ) {
	// 		document.querySelectorAll("p").forEach((item) => {
	// 			item.setAttribute("dir", "rtl");
	// 		});

	// 		dispatch(getServerLanguageHe());
	// 		dispatch(setlanguageHe());
	// 		document.querySelector(".app").classList.add("he");
	// 		mainLang.setAttribute("data-lang", "he");
	// 		mainLang.textContent = "he";
	// 		secondLang.textContent = "ru";
	// 		secondLang.setAttribute("data-lang", "ru");
	// 		return;
	// 	}
	// 	if (language === "ru") {
	// 		document
	// 			.querySelectorAll("p")
	// 			.forEach((item) => item.setAttribute("dir", "ltr"));

	// 		document.querySelector(".app").classList.remove("he");
	// 		dispatch(getServerLanguageRu());
	// 		dispatch(setlanguageRu());

	// 		mainLang.setAttribute("data-lang", "ru");
	// 		mainLang.textContent = "ru";
	// 		secondLang.textContent = "he";
	// 		secondLang.setAttribute("data-lang", "he");
	// 	}
	// }, []);

	// useEffect(() => {
	// 	const language = localStorage.getItem("curent-language");

	// 	document.querySelector(".languages__box").classList.toggle("active");

	// 	const mainLang = document.querySelector(".languages__btn_main");
	// 	const secondLang = document.querySelector(".languages__btn_second");

	// 	if (language === "he" || !language) {
	// 		// const name=	document.querySelector('.about__name').setAttribute('dir', 'rtl');
	// 		// name?.setAttribute('dir', 'rtl');
	// 		document.querySelectorAll("p").forEach((item) => {
	// 			item.setAttribute("dir", "rtl");
	// 		});

	// 		dispatch(getServerLanguageHe());
	// 		dispatch(setlanguageHe());
	// 		document.querySelector(".app").classList.add("he");
	// 		mainLang.setAttribute("data-lang", "he");
	// 		mainLang.textContent = "he";
	// 		secondLang.textContent = "ru";
	// 		secondLang.setAttribute("data-lang", "ru");
	// 		return;
	// 	}
	// 	if (language === "ru") {
	// 		document
	// 			.querySelectorAll("p")
	// 			.forEach((item) => item.setAttribute("dir", "ltr"));

	// 		document.querySelector(".app").classList.remove("he");
	// 		dispatch(getServerLanguageRu());
	// 		dispatch(setlanguageRu());

	// 		mainLang.setAttribute("data-lang", "ru");
	// 		mainLang.textContent = "ru";
	// 		secondLang.textContent = "he";
	// 		secondLang.setAttribute("data-lang", "he");
	// 	}
	// }, [dispatch]);
	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	const current_language = useSelector((state) => state?.server?.language);
	const GET_LOCATIONS = gql`
		{
			allSites {
				logoTitle${current_language}
				logoDescription${current_language}
				favicon
			}
		}
	`;
	const { data } = useQuery(GET_LOCATIONS);
	const desc = data?.allSites[0];

	return (
		<div className="main-page he">
			<HelmetConfig
				title={desc?.[`logoTitle${current_language}`]}
				desc={desc?.[`logoDescription${current_language}`]}
			/>
			<Header isMain={true} />
			<Preview />
			<About />
			<ProblemSolving />
			<Services />
			<Reviews />
			<Blog />
			<Contacts />
			<Footer isMain={true} />
		</div>
	);
}
