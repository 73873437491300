import { useSelector } from "react-redux";
import Form from "../form/Form";
import Detail from "./detail/Detail";
import "./Services.scss";
import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";

export default function Services() {
	const price = useSelector((state) => state.main.language.price);
	const id = useSelector((state) => state?.server);
	const current_language = useSelector((state) => state?.server?.language);

	const GET_LOCATIONS = gql`
	{
		siteById(id: "${id?.id}") {
		  mainPage {
				servicesBlockTitle${current_language}
				serviceSet {
					name${current_language}
					price
				}
		}
	}
	}
	`;

	const { data } = useQuery(GET_LOCATIONS);
	const info = data?.siteById?.mainPage;

	if (info !== undefined) {
		return (
			<>
				<div className="services wrapper">
					<div className="services__container wrapper-box">
						<h2 className="services__title">
							{info[`servicesBlockTitle${current_language}`]}
						</h2>

						<div className="services__row">
							<div className="services__item">
								{info?.serviceSet?.map((item, i) => (
									<div className="services__box" key={i}>
										<p className="services__desc">{item[`name${current_language}`]}</p>
										<p className="services__text">
											{item.price}
											<span> {price}</span>
										</p>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				<Form />
				<Detail />
			</>
		);
	}
}
