import { useSelector } from "react-redux";
import "./Preview.scss";
import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import img from "./../../images/7c130368686ab960d35e3beb395c7be3_1601879710_apparatnyjpedikyur2048x13711768x432.jpg";
// import img from "./../../images/podolog-serpuhov.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation } from "swiper";
export default function Preview() {
	const preview = useSelector((state) => state.main.language.preview);
	const id = useSelector((state) => state?.server);
	const current_language = useSelector((state) => state?.server?.language);

	const GET_LOCATIONS = gql`
	{
		sliders {
			name
			image
   	 	text${current_language}
    	title${current_language}
			button {
				linkText
				title${current_language}
			}
		}
		siteById(id: "${id?.id}") {
		
		  mainPage {
				profession${current_language}
				doctorName${current_language}
				topImage
				avatar
				
			}
		}
	}
	`;

	const { data } = useQuery(GET_LOCATIONS);
	const info = data?.siteById?.mainPage;

	return (
		<>
			<Swiper
				className="swiper-preview "
				spaceBetween={50}
				slidesPerView={1}
				navigation={true}
				modules={[Navigation]}>
				{data?.sliders?.map((item, key) => (
					<SwiperSlide key={key}>
						<div
							className="slider-swiper"
							style={{
								background: `no-repeat url(https://back.avalon.co.il:8002/media/${item.image})`,
							}}>
							<div className="slider-swiper__box">
								<div className="slider-swiper__text">
									<p className="slider-swiper__title">
										{item?.[`title${current_language}`]}
									</p>
									<p className="slider-swiper__desc">
										{item?.[`text${current_language}`]}
									</p>

									{item.button && (
										<a
											className="button-on-slider button-dark"
											href={item?.button?.linkText}>
											{item?.button[`title${current_language}`]}
										</a>
									)}
								</div>
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
			{/* <div className="preview">
				<div className="preview__container">
					<div
						className="preview__bg"
						style={{
							background: ` center/cover no-repeat url(https://back.avalon.co.il:8002/media/${info?.topImage})`,
						}}></div>
					<div className="wrapper">
						<div className="preview__info">
							<div
								className="preview__img"
								style={{
									background: ` center/cover no-repeat url(https://back.avalon.co.il:8002/media/${info?.avatar})`,
								}}></div>
							<p className="preview__name">
								{data?.siteById?.mainPage[`doctorName${current_language}`]}
								<span className="preview__position">
									{data?.siteById?.mainPage[`profession${current_language}`]}
								</span>
							</p>
						</div>
					</div>
				</div>
			</div> */}
		</>
	);
}
