import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams, Link } from "react-router-dom";

import { setlanguageHe, setlanguageRu } from "../../store/language/actions";
import Languages from "../languages/Languages";
import Social from "../social/Social";
import "./MobileMenu.scss";
import { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import {
	getServerLanguageHe,
	getServerLanguageRu,
} from "../../store/server-info/actions";

export default function MobileMenu({ isMain }) {
	const params=useParams()
	const header = useSelector((state) => state.main.language.header);
	const button = useSelector((state) => state.main.language.buttonsTitle);
	const dispatch = useDispatch();
	// useEffect(() => {
	// 	document.querySelector(".languages__box").classList.toggle("active");

	// 	const mainLang = document.querySelector(".languages__btn_main");
	// 	const secondLang = document.querySelector(".languages__btn_second");

	// 	const language = localStorage.getItem("curent-language");
	// 	if (language === "he" ||!language) {
	// 		document.querySelectorAll('p').forEach(item => item.setAttribute('dir', 'rtl'))
	// 		dispatch(getServerLanguageHe());
	// 		dispatch(setlanguageHe());
	// 		document.querySelector(".app").classList.add("he");
	// 		mainLang.setAttribute("data-lang", "he");
	// 		mainLang.textContent = "he";
	// 		secondLang.textContent = "ru";
	// 		secondLang.setAttribute("data-lang", "ru");
	// 		return;
	// 	}
	// 	if (language === "ru") {
	// 		document.querySelectorAll('p').forEach(item => item.setAttribute('dir', 'ltr'))
	// 		document.querySelector(".app").classList.remove("he");
	// 		dispatch(getServerLanguageRu());
	// 		dispatch(setlanguageRu());

	// 		mainLang.setAttribute("data-lang", "ru");
	// 		mainLang.textContent = "ru";
	// 		secondLang.textContent = "he";
	// 		secondLang.setAttribute("data-lang", "he");
	// 	}
	// }, [dispatch, params.name]);
const navigate = useNavigate()
	const changeLanguage = (e) => {
		dispatch(getServerLanguageHe());

		if (e.target.getAttribute("data-lang") === "he") {
			localStorage.setItem("curent-language", "he");
			document.querySelectorAll('p').forEach(item => item.setAttribute('dir', 'rtl'))
			document.querySelectorAll(".languages__btn").forEach((i) => {
				i.classList.add("active");
			});

			dispatch(setlanguageHe());		localStorage.setItem("lang", "he");
			if (params.name && params.lang) {
				// console.log(`/blog/${params.name}/${params.lang}`)
				navigate(`/blog/${params.name}/he`);
				return
			}
			navigate("/he");
			
		} else {
			document.querySelectorAll('p').forEach(item => item.setAttribute('dir', 'ltr'))
			localStorage.setItem("curent-language", "ru");
			dispatch(getServerLanguageRu());

			dispatch(setlanguageRu());
			document.querySelectorAll(".languages__btn").forEach((i) => {
				i.classList.remove("active");
			});
			localStorage.setItem("lang", "ru");
			if (params.name && params.lang) {
				// console.log(`/blog/${params.name}/${params.lang}`)
				navigate(`/blog/${params.name}/ru`);
				return
			}
			navigate("/ru");
		}
	};

	const id = useSelector((state) => state?.server);
	const current_language = useSelector((state) => state?.server?.language);

	const GET_LOCATIONS = gql`
	{
		siteById(id: "${id?.id}") {
			phone
		header {
      menuItems {
        name${current_language}
      url
      }
		}
		}
	}
	`;

	const { data } = useQuery(GET_LOCATIONS);
	const server_header = data?.siteById?.header?.menuItems;
	const lang = localStorage.getItem("lang"); 
	const handleClick = (e) => {
		document
			.querySelectorAll(".header__link ")
			.forEach((item) => item.classList.remove("active"));
		e.target.classList.add("active");
		document.querySelector(".mobile-menu").classList.remove("active");
		document.querySelector("body").classList.remove("scroll-hidden");
		document.querySelector(".mobile-button").classList.remove("close");
	};
	return (
		<div className="mobile-menu header">
			<div className="mobile-menu__container">
				<div className="mobile-menu__header">
					<NavLink to={`/${params.lang}`} className="header__logo"></NavLink>
				</div>
				<Languages changeLanguage={changeLanguage} />
				<nav className="header__nav">
					{(isMain &&
						server_header?.map((link, index) => (
						
							<Link
								onClick={handleClick}
								key={index}
								to={`/${lang}/?scrollTo=${link.url}`}
								spy={true}
								smooth={true}
								offset={100}
								duration={500}
								className={link.class ? "header__link active " : "header__link "}>
								{link[`name${current_language}`]}
							</Link>
						))) ||
						server_header?.map((link, index) => (
							<Link
								onClick={handleClick}
								key={index}
								to={`/${lang}/?scrollTo=${link.url}`}
								spy={true}
								smooth={true}
								offset={100}
								duration={500}
								className={link.class ? "header__link active " : "header__link "}>
								{link[`name${current_language}`]}
							</Link>
						))}
				</nav>
				<div className="header__row">
					{/* <Link
						to="form"
						spy={true}
						onClick={handleClick}
						smooth={true}
						offset={100}
						duration={500}
						className="header__button button-transparent">
						{button.name_course}
					</Link> */}
						<a className="header__phone" href="tel:0587722964">{data?.siteById?.phone}</a>
					<Link
						to="form"
						spy={true}
						smooth={true}
						hashSpy={true}
						onClick={handleClick}
						offset={50}
						duration={500}
						className="header__button button-dark">
						{button.name_appointment}
					</Link>
				</div>
				<Social isLight={false} />
				<p className=" footer footer__copy">2023 © All Rights Reserved</p>
			</div>
		</div>
	);
}
