import { gql, useQuery } from "@apollo/client";
import "./Social.scss";
import { useSelector } from "react-redux";

export default function Social({ isLight }) {
	const id = useSelector((state) => state?.server);

	const GET_LOCATIONS = gql`
	{
		siteById(id: "${id?.id}") {
		
		header {
			socialLinks{
				name
				url
				image
			}
    
		}
		}
	}
	`;

	const { data } = useQuery(GET_LOCATIONS);
	const server_header = data?.siteById;
	return (
		<div className="social">
			<div className={isLight ? "social__container light " : "social__container"}>
				{server_header?.header?.socialLinks.map((item, i)=>(
						<a key={i} href={item.url} style={{background: `center/cover no-repeat url(https://back.avalon.co.il:8002/media/${item.image})`}} className={`social__link`}></a>
				))}
				{/* <a href="#" className="social__link social__link_instagram "></a>
				<a href="#" className="social__link social__link_telegram"></a>
				<a href="#" className="social__link social__link_facebook"></a> */}
			</div>
		</div>
	);
}
