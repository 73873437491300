import img from '../../../images/image1.png';
import img2 from '../../../images/image2.png';
import img3 from '../../../images/image3.png';
import reviews_img_1 from '../../../images/reviews-photo-1.png';
import reviews_img_2 from '../../../images/reviews-photo-2.png';
import blog_img_1 from '../../../images/blog-img-1.png';
import blog_img_2 from '../../../images/blog-img-2.png';
import blog_img_3 from '../../../images/blog-img-3.png';


export const ru = {
  messages: {
    adress: `пожалуйста введите EMAIL`,
    username: "пожалуйста введите имя",
    phone: "пожалуйста введите номер телефона",
    succes :'ваше обращение принято, с вами обязательно свяжутся'


  },
  price: '₪.',
  days: 'дней',
  email: 'Email',
  phone: 'Phone',
  office: "Office",


  buttonsTitle: {
    name_course: `Записаться на курсы`,
    name_appointment: 'Записаться на прием'

  },
  header: {
    links: [
      {
        title: 'Обо мне',
        url: 'about',
        class: 'active'
      },
      {
        title: 'Услуги',
        url: 'services'
      },
      {
        title: 'Курсы',
        url: 'detail'
      },
      {
        title: 'Цены',
        url: 'services '
      }, {
        title: 'Отзывы',
        url: 'reviews'
      }, {
        title: 'Блог',
        url: 'blog'
      }, {
        title: 'Контакты',
        url: 'contacts'
      },
    ],
    linksDefault: [
      {
        title: 'Обо мне',
        url: '/',
        class: 'active'
      },
      {
        title: 'Услуги',
        url: '/'
      },
      {
        title: 'Курсы',
        url: '/'
      },
      {
        title: 'Цены',
        url: '/'
      }, {
        title: 'Отзывы',
        url: '/'
      }, {
        title: 'Блог',
        url: '/'
      }, {
        title: 'Контакты',
        url: '/'
      },
    ]

  },
  preview: {
    name: "Liora Lev",
    position: "Врач-подолог"
  },
  about: {
    title: "Обо мне",
    additional: 'Нам доверяют потому что мы ',
    additional2: ' в своем деле',
    span: "лучшие",
    desc: [
      {
        info: `Повседневная практика показывает, что сплочённость команды профессионалов 
            выявляет срочную потребность благоприятных перспектив.учётом комплекса приоретизации
             разума над эмоциями.  Однако конкретные выводы, разумеется, подвергнуты целой серии 
             независимых исследований.`
      }, {
        info: ` Таким образом,
                семантический разбор внешних противодействий говорит о возможностях новых предложений.Вот вам яркий пример современных тенденций — курс на социально - ориентированный национальный проект обеспечивает актуальность поэтапного и последовательного развития общества.В рамках спецификации современных стандартов,
                стремящиеся вытеснить традиционное производство,
                нанотехнологии являются только методом политического участия и призваны к ответу.`
      },
    ],
    items: [
      {
        icon: 'shcool',
        title: 'Высшее медицинское образование',
        desc: `Но внедрение современных методик играет определяющее обществом задач.`
      }, {
        icon: 'account',
        title: 'Большой опыт работы',
        desc: `Но внедрение современных методик играет определяющее обществом задач.`
      }, {
        icon: 'notice',
        title: 'Еженедельные публикации',
        desc: `Но внедрение современных методик играет определяющее обществом задач.`
      }, {
        icon: 'certificate',
        title: 'Сертификаты качества обслуживания',
        desc: `Но внедрение современных методик играет определяющее обществом задач.`
      },

    ]
  },
  problems: {
    title: 'Какие проблемы решаю?',
    desc: 'Повседневная практика показывает, что сплочённость команды профессионалов выявляет срочную потребность ',
    accordion: [
      {
        title: 'Удаление мозолей, бородавок',
        desc: `For business professionals caught between high OEM price and mediocre print 
                and graphic output, there’s a solution: Business Express’s Eclipse line of compatible
                 laser toner cartridges that meet or exceed OEM quality for 20% less than typical OEM price.
                  While even brand name OEMs display “This cartridge may contain recycled components” on 
                  packaging, only Business Express.`
      }, {
        title: 'Избавление от трещин на стопе',
        desc: `For business professionals caught between high OEM price and mediocre print 
                and graphic output, there’s a solution: Business Express’s Eclipse line of compatible
                 laser toner cartridges that meet or exceed OEM quality for 20% less than typical OEM price.
                  While even brand name OEMs display “This cartridge may contain recycled components” on 
                  packaging, only Business Express.`
      }, {
        title: 'Вросшие и деформированные ногти',
        desc: `For business professionals caught between high OEM price and mediocre print 
                and graphic output, there’s a solution: Business Express’s Eclipse line of compatible
                 laser toner cartridges that meet or exceed OEM quality for 20% less than typical OEM price.
                  While even brand name OEMs display “This cartridge may contain recycled components” on 
                  packaging, only Business Express.`
      }, {
        title: 'Подологический педикюр',
        desc: `For business professionals caught between high OEM price and mediocre print 
                and graphic output, there’s a solution: Business Express’s Eclipse line of compatible
                 laser toner cartridges that meet or exceed OEM quality for 20% less than typical OEM price.
                  While even brand name OEMs display “This cartridge may contain recycled components” on 
                  packaging, only Business Express.`
      },
    ]
  },
  form: {
    title: 'Записаться на прием',
    desc: '	Напишите сейчас и получите первую консультацию бесплатно',
    label: "	Наши  курсы помогут Вам стать востребованным специалистом",
    placeholderName: "Ваше имя",
    placeholderMail: "Ваша почта",
    placeholderPhone: "Ваш номер телефона"
  },
  services: {
    title: 'Услуги и цены',
    price: [
      {
        title: "Бесплатная консультация",
        price: "0",
        value: "ш"
      },
      {
        title: "Первичный прием",
        price: "1000",
        value: "ш"
      },
      {
        title: "Повторный прием",
        price: "1000",
        value: "ш"
      },
      {
        title: "Короткая консультация",
        price: "300",
        value: "ш"
      }, {
        title: "Расширенная консультация",
        price: "2000",
        value: "ш"
      }, {
        title: "Семейная консультация",
        price: "3000",
        value: "ш"
      },
    ],
    details: [
      {
        title: 'подологический педикюр',
        price: ' 5300',
        days: ' 9дней',
        imgUrl: img,
        items: [
          {
            title: '1GB condimentum'
          }, {
            title: 'Vitae sapienm'
          }, {
            title: 'Pellentesque habitant'
          }, {
            title: 'Netus et malesuada'
          },
        ]
      }, {
        title: 'Вросшие и деформированные ногти',
        price: '1300',
        days: ' 9дней',
        imgUrl: img2,
        items: [
          {
            title: '1GB condimentum'
          }, {
            title: 'Vitae sapienm'
          }, {
            title: 'Pellentesque habitant'
          },
        ]
      }, {
        title: 'Мозоли, бородавки и трещины на стопе',
        price: ' 250',
        days: ' 9дней',
        imgUrl: img3,
        items: [
          {
            title: '1GB condimentum'
          }, {
            title: 'Vitae sapienm'
          }, {
            title: 'Pellentesque habitant'
          }, {
            title: 'Netus et malesuada'
          },
        ]
      },
    ]

  },
  reviews: [
    {
      image: reviews_img_1,
      name: 'Max Copeland',
      desc: 'Следует отметить, что дальнейшее развитие различных форм деятельности не даёт нам иного выбора.'
    },
    {
      image: reviews_img_2,
      name: 'Max Copeland',
      desc: 'Следует отметить, что дальнейшее развитие различных форм деятельности не даёт нам иного выбора.'
    },
    {
      image: reviews_img_1,
      name: 'Max Copeland',
      desc: 'Следует отметить, что дальнейшее развитие различных форм деятельности не даёт нам иного выбора.'
    },
    {
      image: reviews_img_2,
      name: 'Max Copeland',
      desc: 'Следует отметить, что дальнейшее развитие различных форм деятельности не даёт нам иного выбора.'
    }, {
      image: reviews_img_1,
      name: 'Max Copeland',
      desc: 'Следует отметить, что дальнейшее развитие различных форм деятельности не даёт нам иного выбора.'
    },
  ],
  blog: {
    title: "Блог",
    desc: "Повседневная практика показывает, что сплочённость команды профессионалов выявляет срочную потребность ",
    items: [
      {
        title: "Что нельзя делать при вросшем ногте?",
        date: 'Январь 20, 2023',
        img: blog_img_1
      }, {
        title: "Что нельзя делать при вросшем ногте?",
        date: 'Январь 20, 2023',
        img: blog_img_2
      }, {
        title: "Что нельзя делать при вросшем ногте?",
        date: 'Январь 20, 2023',
        img: blog_img_3
      },
    ]
  },
  contacts: [
    {
      label: 'Email',
      desc: 'info@gmail.com'
    }, {
      label: 'Phone',
      desc: '+1 (800) 314 38 43'
    }, {
      label: 'Office',
      desc: '334 Goldner Manor Suite 430'
    },
  ],
  BlogPage: {
    title: "Что нельзя делать при вросшем ногте?",
    date: 'Январь 20, 2023',
    text: `Высокий уровень вовлечения представителей целевой аудитории является четким доказательством простого факта: новая модель организационной деятельности требует определения и уточнения поставленных обществом задач. Равным образом, глубокий уровень погружения, а также свежий взгляд на привычные вещи — безусловно открывает новые горизонты для стандартных подходов. Сложно сказать, почему сторонники тоталитаризма в науке призывают нас к новым свершениям, которые, в свою очередь, должны быть превращены в посмешище, хотя само их существование приносит несомненную пользу обществу.

    Значимость этих проблем настолько очевидна, что высокотехнологичная концепция общественного уклада является качественно новой ступенью поэтапного и последовательного развития общества. Господа, глубокий уровень погружения требует от нас анализа своевременного выполнения сверхзадачи! Сложно сказать, почему акционеры крупнейших компаний объявлены нарушающими общечеловеческие нормы этики и морали. Безусловно, убеждённость некоторых оппонентов обеспечивает актуальность благоприятных перспектив.`
  }

}
