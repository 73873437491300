import { useSelector } from "react-redux";
import "./Email.scss";
import { useEffect } from "react";

export default function Email() {
	const message = useSelector((state) => state.message.message);
	useEffect(() => {
		if (message !== "") {
			document.querySelector(".message").classList.add("active");
			let timerId = setTimeout(
				() => document.querySelector(".message").classList.remove("active"),
				3000
			);
		}
	}, [message]);
	return <p className="message ">{message}</p>;
}
