import {useEffect} from 'react';

import {Route, Routes, useParams} from 'react-router-dom';
import './App.scss';
import Contacts from './contacts/Contacts';
import Footer from './footer/Footer';
import Header from './header/Header';
import BlogPage from './pages/blog/BlogPage';
import MainPage from './pages/main/MainPage';
import {gql, useQuery} from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux';
import {getServerLanguageHe, getServerLanguageRu, getSiteId} from '../store/server-info/actions';
import {setlanguageHe, setlanguageRu} from '../store/language/actions';
import Email from './email/Email';
import Hemlet from '../Helmet/Helmet';

function App() {
  const GET_LOCATIONS = gql `
  {
    allSites{
      id
       }
  }
  `;
  const {data} = useQuery(GET_LOCATIONS);
  const id = data ?. allSites[0] ?. id;
  const dispatch = useDispatch()

  useEffect(() => {

   
    if (id) 
      dispatch(getSiteId(id))

    

  }, [id, dispatch]);
  useEffect(() => {
 
    if (!data) {
      return
    }
   

    const mainLang = document.querySelector(".languages__btn_main");
    const secondLang = document.querySelector(".languages__btn_second");

    const language = localStorage.getItem('curent-language');

    if (language === 'he' && data) {
      document.querySelectorAll('p').forEach(item => item.setAttribute('dir', 'rtl'))
      document.querySelector(".languages__box")?.classList.toggle("active");

      dispatch(getServerLanguageHe());
      dispatch(setlanguageHe());
      document.querySelector(".app").classList.add("he");
      mainLang.setAttribute("data-lang", "he");
      mainLang.textContent = "he";
      secondLang.textContent = "ru";
      secondLang.setAttribute("data-lang", "ru");
      return;
    }
    if (!language ) { document.querySelector(".languages__box")?.classList.toggle("active");
      document.querySelectorAll('p').forEach(item => item.setAttribute('dir', 'rtl'))


      dispatch(getServerLanguageHe());
      dispatch(setlanguageHe());
      document.querySelector(".app").classList.add("he");
      mainLang.setAttribute("data-lang", "he");
      mainLang.textContent = "he";
      secondLang.textContent = "ru";
      secondLang.setAttribute("data-lang", "ru");
      return;
    }
    if (language === 'ru' && data) {
       document.querySelector(".languages__box")?.classList.toggle("active");
      document.querySelectorAll('p').forEach(item => item.setAttribute('dir', 'ltr'))

      document.querySelector(".app").classList.remove("he");
      dispatch(getServerLanguageRu());
      dispatch(setlanguageRu());


      mainLang.setAttribute("data-lang", "ru");
      mainLang.textContent = "ru";
      secondLang.textContent = "he";
      secondLang.setAttribute("data-lang", "he");
    }
  }, []);
  const state = useSelector(state => state);
  const params = useParams()


  // useEffect(() => {
  // document.querySelector(".languages__box").classList.toggle("active");

  // const mainLang = document.querySelector(".languages__btn_main");
  // const secondLang = document.querySelector(".languages__btn_second");

  // const language = localStorage.getItem('curent-language');
  // if (language === 'he') {
  //     document.querySelectorAll('p').forEach(item => item.setAttribute('dir', 'rtl'))


  //     dispatch(getServerLanguageHe());
  //     dispatch(setlanguageHe());
  //     document.querySelector(".app").classList.add("he");
  //     mainLang.setAttribute("data-lang", "he");
  //     mainLang.textContent = "he";
  //     secondLang.textContent = "ru";
  //     secondLang.setAttribute("data-lang", "ru");
  //     return;
  // }
  // if (language === 'ru') {
  //     document.querySelectorAll('p').forEach(item => item.setAttribute('dir', 'ltr'))

  //     document.querySelector(".app").classList.remove("he");
  //     dispatch(getServerLanguageRu());
  //     dispatch(setlanguageRu());


  //     mainLang.setAttribute("data-lang", "ru");
  //     mainLang.textContent = "ru";
  //     secondLang.textContent = "he";
  //     secondLang.setAttribute("data-lang", "he");
  // }
  // }, [params.name, dispatch]);

  return (
    <div className="app ">
      {
      state ?. server ?. id && <>
        <Email/>
     {/* <Helmet>
        <title>Podo School</title>
        <meta name="description" content="Nested component"/>
      </Helmet> */}
        <Routes>
          <Route path="/"
            element={<MainPage/>}/>
             <Route path="/:lang"
            element={<MainPage/>}/>
          <Route path="/blog/:name/:lang"
            element={<BlogPage/>}/>
        </Routes>
      </>
    }

      {/* <div style={{background: `url(https://back.avalon.co.il:8002/media/${data?.allSites[0]?.mainPage?.avatar})`}}>ff</div> */} </div>
  );
};

export default App;
