import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import "./Blog.scss";
import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import DOMParserReact from "dom-parser-react";
import { getServerLanguageHe, getServerLanguageRu } from "../../store/server-info/actions";
import { setlanguageHe, setlanguageRu } from "../../store/language/actions";

export default function Blog() {
	const blog = useSelector((state) => state.main.language.blog);
	const id = useSelector((state) => state?.server);
	const current_language = useSelector((state) => state?.server?.language);

	const GET_LOCATIONS = gql`
	{
		allPosts {
			title${current_language}
			mainImage
			publish
			slug
		}
			siteById(id: "${id?.id}") {
		
		  mainPage {
					blogBlockText${current_language}
					blogBlockTitle${current_language}
		}
	}
	}
	`;

	const { data } = useQuery(GET_LOCATIONS);
	const info = data?.siteById;
	const dispatch = useDispatch();
	const params = useParams()
	const language = localStorage.getItem("curent-language");
	useEffect(() => {

		const mainLang = document.querySelector(".languages__btn_main");
		const secondLang = document.querySelector(".languages__btn_second");


		if (params.lang === "he" && data) {
			document
				.querySelectorAll("p")
				.forEach((item) => item.setAttribute("dir", "rtl"));

			return;
		}
		
		// if (!params.lang) {
		// 	document
		// 		.querySelectorAll("p")
		// 		.forEach((item) => item.setAttribute("dir", "rtl"));

		// 	dispatch(getServerLanguageHe());
		// 	dispatch(setlanguageHe());
		// 	document.querySelector(".app").classList.add("he");
		// 	mainLang.setAttribute("data-lang", "he");
		// 	mainLang.textContent = "he";
		// 	secondLang.textContent = "ru";
		// 	secondLang.setAttribute("data-lang", "ru");
		// 	return;
		// }
		if (params.lang === "ru" && data) {
			document
			.querySelectorAll(".form__title")
			.forEach((item) => item.setAttribute("dir", "ltr"));
			document
				.querySelectorAll("p")
				.forEach((item) => item.setAttribute("dir", "ltr"));

	
		}
		// document.querySelectorAll('p').forEach(i=>i.setAttribute('dir', 'ltr'))
	}, [data]);
	return (
		<div className="blog">
			<div className="blog__container">
				<div className="wrapper">
					<div className="wrapper-box">
						<p className="blog__title">
							{info?.mainPage[`blogBlockTitle${current_language}`]}
						</p>
						<p className="blog__desc">
						<DOMParserReact source=		{info?.mainPage[`blogBlockText${current_language}`]}/>
					
						</p>
						<div className="blog__row">
							{data?.allPosts?.map((item, index) => (
								<Link to={`/blog/${item.slug}/${params.lang}`} className="blog__item" key={index}>
									<div
										className="blog__img"
										style={{
											background: `center/cover no-repeat url(https://back.avalon.co.il:8002/media/${item.mainImage})`,
										}}></div>
									<p className="blog__text">
										<span>{item.publish}</span>
										{item[`title${current_language}`]}
									</p>
								</Link>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
