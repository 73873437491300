import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-scroll";
import "./Detail.scss";
import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { getServerLanguageHe, getServerLanguageRu } from "../../../store/server-info/actions";
import { setlanguageHe, setlanguageRu } from "../../../store/language/actions";

export default function Detail() {
	const button = useSelector((state) => state.main.language.buttonsTitle);
	const dispatch = useDispatch();
	const detail = useSelector((state) => state.main.language.services.details);

	const price = useSelector((state) => state.main.language.price);
	const days = useSelector((state) => state.main.language.days);

	const id = useSelector((state) => state?.server);
	const current_language = useSelector((state) => state?.server?.language);

	const GET_LOCATIONS = gql`
	{
		siteById(id: "${id?.id}") {
		  mainPage {
				courseSet {
					courseLabels{
						label${current_language}
					}
					name${current_language}
					image
					price
					days
				}
		}
	}
	}
	`;

	const { data } = useQuery(GET_LOCATIONS);
	const info = data?.siteById;
	const language = localStorage.getItem("curent-language");
	// // const dispatch = useDispatch();
	// useEffect(() => {
	// 	const mainLang = document.querySelector(".languages__btn_main");
	// 	const secondLang = document.querySelector(".languages__btn_second");

	// 	const language = localStorage.getItem("curent-language");
	// 	if (!language ) {
	// 		document
	// 			.querySelectorAll("p")
	// 			.forEach((item) => item.setAttribute("dir", "rtl"));

	// 		dispatch(getServerLanguageHe());
	// 		dispatch(setlanguageHe());
	// 		document.querySelector(".app").classList.add("he");
	// 		mainLang.setAttribute("data-lang", "he");
	// 		mainLang.textContent = "he";
	// 		secondLang.textContent = "ru";
	// 		secondLang.setAttribute("data-lang", "ru");
	// 		return;
	// 	}
	// 	if (language === "he" && data) {
	// 		document
	// 			.querySelectorAll("p")
	// 			.forEach((item) => item.setAttribute("dir", "rtl"));

	// 		dispatch(getServerLanguageHe());
	// 		dispatch(setlanguageHe());
	// 		document.querySelector(".app").classList.add("he");
	// 		mainLang.setAttribute("data-lang", "he");
	// 		mainLang.textContent = "he";
	// 		secondLang.textContent = "ru";
	// 		secondLang.setAttribute("data-lang", "ru");
	// 		return;
	// 	}
	// 	if (language === "ru" && data) {
	// 		document
	// 		.querySelectorAll(".form__title")
	// 		.forEach((item) => item.setAttribute("dir", "ltr"));
	// 		document
	// 			.querySelectorAll("p")
	// 			.forEach((item) => item.setAttribute("dir", "ltr"));

	// 		document.querySelector(".app").classList.remove("he");
	// 		dispatch(getServerLanguageRu());
	// 		dispatch(setlanguageRu());

	// 		mainLang.setAttribute("data-lang", "ru");
	// 		mainLang.textContent = "ru";
	// 		secondLang.textContent = "he";
	// 		secondLang.setAttribute("data-lang", "he");
	// 	}
	// 	// document.querySelectorAll('p').forEach(i=>i.setAttribute('dir', 'ltr'))
	// }, [data, language]);
	return (
		<div className="detail">
			{info?.mainPage?.courseSet?.map((item, index) => (
				<div className="detail__container wrapper" key={index}>
					<div className="detail__box">
						<div
							className="detail__img"
							style={{
								backgroundImage: `url(https://back.avalon.co.il:8002/media/${item.image})`,
							}}></div>
						<div className="detail__info">
							<div className="detail__wrapper">
								<p className="detail__name">{item[`name${current_language}`]}</p>
								<p className="detail__price">
									<span>{price}</span> {item.price}
								</p>
								<p className="detail__days">
									{item.days} {days}
								</p>
								<div className="detail__text">
									{item?.courseLabels?.map((item, i) => (
										<p key={i}>{item[`label${current_language}`]}</p>
									))}
								</div>
								<Link to="form" className="button-dark detail__button">
									{button.name_course}
								</Link>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
}
