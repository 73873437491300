import img from '../../../images/image1.png';
import img2 from '../../../images/image2.png';
import img3 from '../../../images/image3.png';
import reviews_img_1 from '../../../images/reviews-photo-1.png';
import reviews_img_2 from '../../../images/reviews-photo-2.png';
import blog_img_1 from '../../../images/blog-img-1.png';
import blog_img_2 from '../../../images/blog-img-2.png';
import blog_img_3 from '../../../images/blog-img-3.png';


export const he = {
  messages: {
    adress: `נא להזין מייל`,
    username: "נא להזין שם",
    phone: "נא להזין מספר טלפון",
    succes :'בקשתך התקבלה, ניצור איתך קשר'

  },
  price: '.₪',
  days:'ימים',

  email:'אימייל',
  phone:'טלפון',
  office:"מִשׂרָד",
  



  buttonsTitle: {
    name_course: 'לקבוע פגישה',
    name_appointment: 'לקבוע פגישה'

  },
  header: {
    links: [
      {
        title: 'עליי',
        url: 'about',
        class: 'active'
      },
      {
        title: 'שירותים',
        url: 'services'
      },
      {
        title: 'קורסים',
        url: 'detail'
      },
      {
        title: 'מחירים',
        url: 'services'
      }, {
        title: 'ביקורות',
        url: 'reviews'
      }, {
        title: 'בלוג',
        url: 'blog'
      }, {
        title: 'אנשי קשר',
        url: 'contacts'


      },
    ],  linksDefault: [
      {
        title: 'עליי',
        url: '/',
        class: 'active'
      },
      {
        title: 'שירותים',
        url: '/',
      },
      {
        title: 'קורסים',
        url: 'detail'
      },
      {
        title: 'מחירים',
        url: '/',
      }, {
        title: 'ביקורות',
        url: '/',
      }, {
        title: 'בלוג',
        url: '/',
      }, {
        title: 'אנשי קשר',
        url: '/',
      },
    ]

  },
  preview: {
    name: "Liora Lev",
    position: "פודיאטר"
  },
  about: {
    title: "עליי",
    additional: 'Нסומכים עלינו כי אנחנו הטובים ביותר בעסק שלנו ',
    additional2: ' ',
    span: "",
    desc: [
      {
        info: `התרגול היומיומי מראה שהלכידות של צוות אנשי מקצוע מגלה צורך דחוף בסיכויים נוחים, תוך התחשבות בתעדוף המורכב של התבונה על פני רגשות. עם זאת, מסקנות ספציפיות, כמובן, הועברו לסדרה שלמה של מחקרים עצמאיים.  לפיכך, הניתוח הסמנטי של פעולות נגד חיצוניות מדבר על האפשרויות של הצעות חדשות. הנה דוגמה חיה למגמות עכשוויות - הקורס לקראת פרויקט לאומי בעל אוריינטציה חברתית מבטיח את הרלוונטיות של שלב אחר שלב והתפתחות עקבית של החברה. במסגרת המפרט`
      }, {
        info: `התרגול היומיומי מראה שהלכידות של צוות אנשי מקצוע מגלה צורך דחוף בסיכויים נוחים, תוך התחשבות בתעדוף המורכב של התבונה על פני רגשות. עם זאת, מסקנות ספציפיות, כמובן, הועברו לסדרה שלמה של מחקרים עצמאיים.  לפיכך, הניתוח הסמנטי של פעולות נגד חיצוניות מדבר על האפשרויות של הצעות חדשות. הנה דוגמה חיה למגמות עכשוויות - הקורס לקראת פרויקט לאומי בעל אוריינטציה חברתית מבטיח את הרלוונטיות של שלב אחר שלב והתפתחות עקבית של החברה. במסגרת המפרט`
      },
    ],
    items: [
      {
        icon: 'shcool',
        title: 'השכלה רפואית גבוהה',
        desc: `אבל הכנסת טכניקות מודרניות משחקת משימה מכוננת עבור החברה.`
      }, {
        icon: 'account',
        title: 'ניסיון עבודה רב',
        desc: `אבל הכנסת טכניקות מודרניות משחקת משימה מכוננת עבור החברה.`
      }, {
        icon: 'notice',
        title: 'פרסומים שבועיים',
        desc: `אבל הכנסת טכניקות מודרניות משחקת משימה מכוננת עבור החברה.`
      }, {
        icon: 'certificate',
        title: 'תעודות איכות שירות',
        desc: `אבל הכנסת טכניקות מודרניות משחקת משימה מכוננת עבור החברה.`
      },

    ]
  },
  problems: {
    title: '?אילו בעיות אני פותר',
    desc: 'התרגול היומיומי מלמד כי לכידות של צוות אנשי מקצוע מגלה צורך דחוף ',
    accordion: [
      {
        title: 'הסרת יבלות, יבלות',
        desc: `לאנשי מקצוע עסקיים הנקלעים בין מחיר OEM גבוה לבין פלט הדפסה וגרפי בינוני,
         יש פתרון: קו Eclipse של מחסניות לייזר תואמות של Business Express העומדות באיכות 
         OEM או עולות עליה ב-20% פחות ממחיר OEM טיפוסי. בעוד שאפילו יצרני OEM של מותגים מציגים את
          "מחסנית זו עשויה 
         להכיל רכיבים 
         ממוחזרים" על
          האריזה, רק`
      }, {
        title: 'להיפטר מכפות רגליים סדוקות',
        desc: `לאנשי מקצוע עסקיים הנקלעים בין מחיר OEM גבוה לבין פלט הדפסה וגרפי בינוני,
        יש פתרון: קו Eclipse של מחסניות לייזר תואמות של Business Express העומדות באיכות 
        OEM או עולות עליה ב-20% פחות ממחיר OEM טיפוסי. בעוד שאפילו יצרני OEM של מותגים מציגים את
         "מחסנית זו עשויה 
        להכיל רכיבים 
        ממוחזרים" על
         האריזה, רק`
      }, {
        title: 'ציפורניים חודרניות ומעוותות',
        desc: `לאנשי מקצוע עסקיים הנקלעים בין מחיר OEM גבוה לבין פלט הדפסה וגרפי בינוני,
        יש פתרון: קו Eclipse של מחסניות לייזר תואמות של Business Express העומדות באיכות 
        OEM או עולות עליה ב-20% פחות ממחיר OEM טיפוסי. בעוד שאפילו יצרני OEM של מותגים מציגים את
         "מחסנית זו עשויה 
        להכיל רכיבים 
        ממוחזרים" על
         האריזה, רק`
      }, {
        title: 'פדיקור פודיאטריה',
        desc: `לאנשי מקצוע עסקיים הנקלעים בין מחיר OEM גבוה לבין פלט הדפסה וגרפי בינוני,
        יש פתרון: קו Eclipse של מחסניות לייזר תואמות של Business Express העומדות באיכות 
        OEM או עולות עליה ב-20% פחות ממחיר OEM טיפוסי. בעוד שאפילו יצרני OEM של מותגים מציגים את
         "מחסנית זו עשויה 
        להכיל רכיבים 
        ממוחזרים" על
         האריזה, רק`
      },
    ]
  },
  form: {
    title: 'לקבוע פגישה',
    desc: '	כתוב עכשיו וקבל ייעוץ ראשון בחינם',
    label: "הקורסים שלנו יעזרו לך להפוך למומחה מבוקש",
    placeholderName: "השם שלך",
    placeholderMail: "האימייל שלך",
    placeholderPhone: "הטלפון שלך"
  },
  services: {
    title: 'שירותים ומחירים',
    price: [
      {
        title: "ייעוץ קצר",
        price: "300",
        value: "ש."
      },
      {
        title: " ייעוץ מורחב",
        price: "2000",
        value: "ש."
      },
      {
        title: "ייעוץ משפחתי",
        price: "3000",
        value: "ש."
      },
      {
        title: "ייעוץ חינם",
        price: "0",
        value: "ש."
      }, {
        title: "מינוי ראשוני",
        price: "1000",
        value: "ש."
      }, {
        title: "קבלה חוזרת",
        price: "500",
        value: "ש."
      },
    ],
    details: [
      {
        title: 'פדיקור פודיאטריה',
        price: ' 5300',
        days: ' 9 ימים',
        imgUrl: img,
        items: [
          {
            title: '1GB condimentum'
          }, {
            title: 'Vitae sapienm'
          }, {
            title: 'Pellentesque habitant'
          }, {
            title: 'Netus et malesuada'
          },
        ]
      }, {
        title: 'פדיקור פודיאטריה',
        price: '1300',
        days: ' 9 ימים',
        imgUrl: img2,
        items: [
          {
            title: '1GB condimentum'
          }, {
            title: 'Vitae sapienm'
          }, {
            title: 'Pellentesque habitant'
          },
        ]
      }, {
        title: 'פדיקור פודיאטריה',
        price: ' 250',
        days: ' 9 ימים',
        imgUrl: img3,
        items: [
          {
            title: '1GB condimentum'
          }, {
            title: 'Vitae sapienm'
          }, {
            title: 'Pellentesque habitant'
          }, {
            title: 'Netus et malesuada'
          },
        ]
      },
    ]

  },
  reviews: [
    {
      image: reviews_img_1,
      name: 'מקס קופלנד',
      desc: 'יש לציין כי המשך הפיתוח של צורות פעילות שונות אינו נותן לנו ברירה אחרת.'
    },
    {
      image: reviews_img_2,
      name: 'מקס קופלנד',
      desc: 'יש לציין כי המשך הפיתוח של צורות פעילות שונות אינו נותן לנו ברירה אחרת.'
    },
    {
      image: reviews_img_1,
      name: 'מקס קופלנד',
      desc: 'יש לציין כי המשך הפיתוח של צורות פעילות שונות אינו נותן לנו ברירה אחרת.'
    },
    {
      image: reviews_img_2,
      name: 'מקס קופלנד',
      desc: 'יש לציין כי המשך הפיתוח של צורות פעילות שונות אינו נותן לנו ברירה אחרת.'
    }, {
      image: reviews_img_1,
      name: 'מקס קופלנד',
      desc: 'יש לציין כי המשך הפיתוח של צורות פעילות שונות אינו נותן לנו ברירה אחרת.'
    },

  ],
  blog: {
    title: "בלוג",
    desc: "התרגול היומיומי מלמד כי לכידות של צוות אנשי מקצוע מגלה צורך דחוף",
    items: [
      {
        title: "מה לא ניתן לעשות עם ציפורן חודרנית??",
        date: '20 בינואר 2023',
        img: blog_img_1
      }, {
        title: "מה לא ניתן לעשות עם ציפורן חודרנית??",
        date: '20 בינואר 2023',
        img: blog_img_2
      }, {
        title: "מה לא ניתן לעשות עם ציפורן חודרנית??",
        date: '20 בינואר 2023',
        img: blog_img_3
      },
    ]
  },
  contacts: [
    {
      label: 'אימייל',
      desc: 'info@gmail.com'
    }, {
      label: 'טלפון',
      desc: '+1 (800) 314 38 43'
    }, {
      label: 'מִשׂרָד',
      desc: '334 גולדנר מנור סוויטה 430'
    },
  ],
  BlogPage: {
    title: "מה לא ניתן לעשות עם ציפורן חודרנית",
    date: '20 בינואר 2023',
    text: `רמת הזיהוי הגבוהה של התצפיות שזוהו היא הוכחה ברורה לעובדה: המודל של פעילות ארגונית 
    חדשה מחייב הגדרה והבהרה של משימות החברה. רמה שווה ועמוקה של טבילה, כמו
     גם מבט רענן על דברים מוכרים - בהחלט נפתחים אופקים חדשים לגישות סטנדרטיות
    . קשה לומר מדוע התפרצויות הטוטליטריות במדע מזמנות אותנו להישגים חדשים, אשר,
     בתורם, חייבים להפוך ללעג, אם כי עצם השפעתם מביאה תועלת ללא ספק לחברה.

    המשמעות של בעיות אלו כל כך ברורה, עד שתפיסת ההייטק של הסדר החברתי היא שלב 
    חדש מבחינה איכותית בהתפתחות ההדרגתית והעקבית של החברה. רבותי, רמה עמוקה של טבילה 
    מחייבת אותנו לנתח את השלמת המשימה החשובה ביותר בזמן! קשה לומר מדוע בעלי המניות של
     החברות הגדולות מוכרזים כמפרים נורמות אוניברסליות של אתיקה ומוסר. מה שבטוח,
     הרשעתם של כמה יריבים מבטיחה את הרלוונטיות של לקוחות פוטנציאליים חיוביים.`
  }


}

