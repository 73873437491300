import cloneDeep from "lodash.clonedeep"
import {GET_EMAIL_MESSAGE} from "./actions"


const defaultState = {
  message: ''
}

export const messageReduser = (state = defaultState, action) => {
  switch (action.type) {
    case GET_EMAIL_MESSAGE:
      {
        const clone = cloneDeep(state);
        clone.message = action.text;
        return clone;
      }

    default:
      return state;

  }
}
