import { useDispatch, useSelector } from "react-redux";
import "./About.scss";
import { gql, useQuery } from "@apollo/client";
import DOMParserReact from "dom-parser-react";
import { useEffect } from "react";
import {
	getServerLanguageHe,
	getServerLanguageRu,
} from "../../store/server-info/actions";
import { setlanguageHe, setlanguageRu } from "../../store/language/actions";
import { useParams } from "react-router-dom";

export default function About() {
	const about = useSelector((state) => state.main.language.about);
	const id = useSelector((state) => state?.server);
	const current_language = useSelector((state) => state?.server?.language);

	const GET_LOCATIONS = gql`
	{
		siteById(id: "${id?.id}"){
		  mainPage {
				profession${current_language}
				doctorName${current_language}
				topImage
				avatar
			  aboutBlockTitle${current_language}
      aboutBlockText${current_language}
			pointBlockTitle${current_language}
			advantageSet {
        icon
        text${current_language}
        name${current_language}
      }
			}
			
		}
	}
	`;

	const { data } = useQuery(GET_LOCATIONS);
	const info = data?.siteById;
	const language = localStorage.getItem("curent-language");
	const dispatch = useDispatch();
	const params = useParams();
	
	useEffect(() => {
		const mainLang = document.querySelector(".languages__btn_main");
		const secondLang = document.querySelector(".languages__btn_second");

		const language = localStorage.getItem("curent-language");
		if (params.lang === "he" && data) {
			document
			.querySelectorAll("h1")
			.forEach((item) => item.setAttribute("dir", "rtl"));
			document
				.querySelectorAll("p")
				.forEach((item) => item.setAttribute("dir", "rtl"));

			dispatch(getServerLanguageHe());
			dispatch(setlanguageHe());
			document.querySelector(".app").classList.add("he");
			mainLang.setAttribute("data-lang", "he");
			mainLang.textContent = "he";
			secondLang.textContent = "ru";
			secondLang.setAttribute("data-lang", "ru");
			return;
		}
		if (!language) {
			document
				.querySelectorAll("p")
				.forEach((item) => item.setAttribute("dir", "rtl"));
			document
				.querySelectorAll("h1")
				.forEach((item) => item.setAttribute("dir", "rtl"));
			dispatch(getServerLanguageHe());
			dispatch(setlanguageHe());
			document.querySelector(".app").classList.add("he");
			mainLang.setAttribute("data-lang", "he");
			mainLang.textContent = "he";
			secondLang.textContent = "ru";
			secondLang.setAttribute("data-lang", "ru");
			return;
		}
		if (params.lang === "ru" && data) {
			document
			.querySelectorAll("h1")
			.forEach((item) => item.setAttribute("dir", "ltr"));
			document
				.querySelectorAll("p")
				.forEach((item) => item.setAttribute("dir", "ltr"));

			document.querySelector(".app").classList.remove("he");
			dispatch(getServerLanguageRu());
			dispatch(setlanguageRu());

			mainLang.setAttribute("data-lang", "ru");
			mainLang.textContent = "ru";
			secondLang.textContent = "he";
			secondLang.setAttribute("data-lang", "he");
		}
		// document.querySelectorAll('p').forEach(i=>i.setAttribute('dir', 'ltr'))
	}, [data, language]);
	return (
		<div className="about">
			<div className="about__bg">
				<div className="wrapper about__flex">
					<div className="about__info">
						<h1 className="about__title">
							{info?.mainPage?.[`doctorName${current_language}`]}
							<span>{info?.mainPage?.[`profession${current_language}`]}</span>
						</h1>
						<div className="about__text">
							<p className="about__desc">
								<DOMParserReact
									source={info?.mainPage[`aboutBlockText${current_language}`]}
								/>
							</p>
						</div>
						{/* <div className="about__scroll"></div> */}
					</div>
					<div
						className="about__img"
						style={{
							background: `center/cover no-repeat url(https://back.avalon.co.il:8002/media/${info?.mainPage?.avatar})`,
						}}></div>
				</div>
			</div>
			<p className="about__additional">
				{info?.mainPage[`pointBlockTitle${current_language}`]}
			</p>
			<div className="about__items">
				<div className="wrapper ">
					<div className=" wrapper-box">
						<div className="about__row">
							{info?.mainPage?.advantageSet?.map((item, index) => (
								<div className="about__item" key={index}>
									<div
										className={`about__icon `}
										style={{
											background: `top/cover no-repeat url(https://back.avalon.co.il:8002/media/${item?.icon})`,
										}}></div>
									<div className="about__box">
										<p className="about__name">{item?.[`name${current_language}`]}</p>
										<p className="about__desc-item">
											{item?.[`text${current_language}`]}
										</p>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
