import { useSelector } from "react-redux";
import { Link } from "react-scroll";
import img1 from "../../images/img1.png";
import img2 from "../../images/img2.png";
import img3 from "../../images/img3.png";
import img4 from "../../images/img4.png";
import img5 from "../../images/img5.png";

import AccordionBox from "../accordion/Accordion";
import "./ProblemSolving.scss";
import { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import DOMParserReact from "dom-parser-react";

export default function ProblemSolving() {
	const button = useSelector((state) => state.main.language.buttonsTitle);

	const accordion = useSelector((state) => state.main.language.problems);

	const id = useSelector((state) => state?.server);
	const current_language = useSelector((state) => state?.server?.language);
useEffect(()=>{
},[])
	const GET_LOCATIONS = gql`
	{
		allSites{    phone}
		siteById(id: "${id?.id}") {
		
		  mainPage {
			  problemsBlockTitle${current_language}
				problemsBlockText${current_language}
			pointBlockTitle${current_language}
			advantageSet {
        icon
        text${current_language}
        name${current_language}
      }
			 workexampleSet {
        image
      }
			}
			
		}
	}
	`;

	const { data } = useQuery(GET_LOCATIONS);
	const info = data?.siteById;
	const images = data?.siteById?.mainPage?.workexampleSet.slice(
		1,
		data?.siteById?.mainPage?.workexampleSet.length
	);

	return (
		<div className="problem-solving problems ">
			<div className="wrapper">
				<div className="problem-solving__container wrapper-box">
					<p className="problem-solving__title">
						{info?.mainPage[`problemsBlockTitle${current_language}`]}
					</p>
					<p className="problem-solving__desc">
					<DOMParserReact source=		{info?.mainPage[`problemsBlockText${current_language}`]}/>
						
					</p>
					<AccordionBox />
					<a
						href={`tel:${data?.allSites[0]?.phone}`}
						className="problem-solving__button button-dark ">
						{button.name_course}
					</a>
				</div>
			</div>
			<div className="problem-solving__gallery gallery">
				<div
					className="problem-solving__item problem-solving__item_first"
					style={{
						background: `center/cover no-repeat  url(https://back.avalon.co.il:8002/media/${info?.mainPage?.workexampleSet[0]?.image})`,
					}}></div>

				<div className="problem-solving__row">
					{images?.map((item, index) => (
						<div
							key={index}
							className="problem-solving__item"
							style={{
								background: `center/cover no-repeat url(https://back.avalon.co.il:8002/media/${item.image})`,
							}}></div>
					))}
				</div>
			</div>
		</div>
	);
}
