import "./Reviews.scss";
import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { gql, useQuery } from "@apollo/client";
import DOMParserReact from "dom-parser-react";
import { getServerLanguageHe, getServerLanguageRu } from "../../store/server-info/actions";
import { setlanguageHe, setlanguageRu } from "../../store/language/actions";
// import "./styles.css";

export default function Reviews() {
	const [swiper, setSwiper] = useState(null);
	const [slidesPerView, setSlidesPerView] = useState(2);

	const reviews = useSelector((state) => state.main.language.reviews);
	const screenWidth = window.screen.width;

	const id = useSelector((state) => state?.server);
	const current_language = useSelector((state) => state?.server?.language);

	const GET_LOCATIONS = gql`
	{
		siteById(id: "${id?.id}") {
		  mainPage {
				reviewSet {
					reviewerName${current_language}
					avatar
					text${current_language}
				}
		}
	}
	}
	`;

	const { data } = useQuery(GET_LOCATIONS);
	const info = data?.siteById?.mainPage;
	const dispatch = useDispatch();
	const language = localStorage.getItem("curent-language");

	useEffect(() => {
		if (screenWidth < 769) {
			setSlidesPerView(1);
		}
	}, [screenWidth]);
	// useEffect(() => {

	// 	const mainLang = document.querySelector(".languages__btn_main");
	// 	const secondLang = document.querySelector(".languages__btn_second");

	// 	const language = localStorage.getItem("curent-language");
	// 	if (!language ) {
	// 		document
	// 			.querySelectorAll("p")
	// 			.forEach((item) => item.setAttribute("dir", "rtl"));

	// 		dispatch(getServerLanguageHe());
	// 		dispatch(setlanguageHe());
	// 		document.querySelector(".app").classList.add("he");
	// 		mainLang.setAttribute("data-lang", "he");
	// 		mainLang.textContent = "he";
	// 		secondLang.textContent = "ru";
	// 		secondLang.setAttribute("data-lang", "ru");
	// 		return;
	// 	}
	// 	if (language === "he" && data) {
	// 		document
	// 			.querySelectorAll("p")
	// 			.forEach((item) => item.setAttribute("dir", "rtl"));

	// 		dispatch(getServerLanguageHe());
	// 		dispatch(setlanguageHe());
	// 		document.querySelector(".app").classList.add("he");
	// 		mainLang.setAttribute("data-lang", "he");
	// 		mainLang.textContent = "he";
	// 		secondLang.textContent = "ru";
	// 		secondLang.setAttribute("data-lang", "ru");
	// 		return;
	// 	}
	// 	if (language === "ru" && data) {
	// 		document
	// 		.querySelectorAll(".form__title")
	// 		.forEach((item) => item.setAttribute("dir", "ltr"));
	// 		document
	// 			.querySelectorAll("p")
	// 			.forEach((item) => item.setAttribute("dir", "ltr"));

	// 		document.querySelector(".app").classList.remove("he");
	// 		dispatch(getServerLanguageRu());
	// 		dispatch(setlanguageRu());

	// 		mainLang.setAttribute("data-lang", "ru");
	// 		mainLang.textContent = "ru";
	// 		secondLang.textContent = "he";
	// 		secondLang.setAttribute("data-lang", "he");
	// 	}
	// 	// document.querySelectorAll('p').forEach(i=>i.setAttribute('dir', 'ltr'))
	// }, [data, language]);
	return (
		<div className="reviews wrapper">
			<div className="wrapper-box">
				<div className="reviews__container">
					<Swiper
						slidesPerView={slidesPerView}
						spaceBetween={30}
						pagination={{
							clickable: true,
						}}
						onSwiper={(swiper) => {
							setSwiper(swiper);
						}}
						// modules={[Pagination]}
						className="swiper">
						{info?.reviewSet?.map((item, index) => (
							<SwiperSlide key={index}>
								<div className="reviews__box">
								{item.avatar ?	<div
										className="reviews__img"
										style={{
											background: `top/cover no-repeat url(https://back.avalon.co.il:8002/media/${item.avatar})`,
										}}></div> : <div
										className="reviews__img reviews__img_default"
									></div>}
									<p className="reviews__name">{item.name}</p>
									<p className="reviews__desc">
									<DOMParserReact
										source={item[`text${current_language}`]}
									/></p>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
					{info?.reviewSet.length > slidesPerView && (
						<div className="swiper-button">
							<button
								className="button button-left button-default"
								onClick={() => {
									swiper.slidePrev();
								}}></button>
							<button
								className="button button-right button-active"
								onClick={() => {
									swiper.slideNext();
								}}></button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
