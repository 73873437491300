import { useDispatch } from "react-redux";
import { setlanguageHe, setlanguageRu } from "../../store/language/actions";
import "./Languages.scss";

export default function Languages({changeLanguage}) {

	return (
		<div className="languages" onClick={changeLanguage}>
			<button className="languages__btn languages__btn_main " data-lang="he">
				he
			</button>
			<div className="languages__box">
				<button className="languages__btn  languages__btn_second " data-lang="ru">
				ru
				</button>
			</div>
		</div>
	);
}
